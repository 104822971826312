<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>海外资源</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in mokuaiList" :key="index" @click="goTab(item.id)"> 
                        <div :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/hwzy'}">海外资源</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: `/ziyuan_state?id=${p_id}`}">国家分区及排列</el-breadcrumb-item>
                        <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details-text">{{hzyname}}</div>
                    <div class="details-list">
                        <div class="itemWrap" v-for="(item,index) in liebiaoList" :key="index">
                            <div class="details-item"   @click="goTo('/ziyuan_liebiao',{gj_id:id,p_id:p_id,id:item.id})">{{item.name}}</div>
                            <!-- <template v-if="item.pla_web=='' || item.pla_web==null">
                                <div class="details-item"   @click="goTo('/ziyuan_liebiao',{gj_id:id,p_id:p_id,id:item.id})">{{item.name}}</div>
                            </template>
                            <template v-if="item.pla_web!=''">
                                <div  class="details-item"  @click="goTo(item.pla_web,{p_id:p_id,id:id,name:hzyname})">{{item.name}}</div>
                            </template> -->
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getplates,getplatexts,getcountrys} from '../../api/index'
    export default {
        data() {
            return {
                p_id:'',
                id:'',
                name:'',
                hzyname:'',
                mokuaiList:[],
                liebiaoList:[]
            }
        },
        created() {
            this.id=this.$route.query.id
            this.p_id=this.$route.query.p_id    
            this.hzycountrys()        
            this.getcountrys()
            this.getplatexts()
        },
        methods: {    
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            goTab(id){
                this.id = id;
                this.getplatexts()
                this.getcountrys()
            },
            // 获取国家上级地区
            hzycountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        for (let index = 0; index < res.data.length; index++) {
                            if (this.p_id==res.data[index].id) {
                                this.name=res.data[index].name
                            }
                            
                        }
                    }
                })
            },
            // 获取左侧国家分类
            getcountrys(){
                getcountrys({
                    p_id:this.p_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.mokuaiList=res.data
                        for (let index = 0; index < res.data.length; index++) {
                            if (this.id==res.data[index].id) {
                                this.hzyname=res.data[index].name
                            }
                        }
                        
                    }

                })
            },
            //右侧
            // getplatexts(){
            //     getplatexts({
            //         plate_id:24,
            //         coun_id:this.id
            //     }).then((res)=>{
            //         // console.log(res);
            //         if (res.code==200) {
            //             this.liebiaoList=res.data.data
            //         }

            //     })
            // },
            getplatexts(){
                getplates({
                    p_id:24,
                    // coun_id:this.id
                }).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.liebiaoList=res.data
                    }

                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 20px;
                .details-text{color: #555555;font-size: 28px;text-align: center;border-bottom: 1px solid #D5D5D5;padding-bottom: 20px;}
                
                .details-list{
                    margin:0 60px;
                    display: flex;
                    flex-wrap: wrap;
                    .itemWrap{
                        width: 18%;
                        height: 44px;
                        font-size: 20px;
                        line-height: 44px;
                        text-align: center;
                        color: #fff;
                        margin-right: 2.5%;
                        margin-top:50px;
                        cursor: pointer;                        
                        border-radius: 6px;
                        &:nth-child(5n+0){margin-right: 0;background: linear-gradient(138deg, #3D76CE, #75AAFC);}
                        &:nth-child(5n+1){background: linear-gradient(138deg, #CF694E, #FD9976);}
                        &:nth-child(5n+2){background: linear-gradient(138deg, #3D76CE,#75AAFC);}
                        &:nth-child(5n+3){background: linear-gradient(138deg, #45CCF9, #7EDFFF);}
                        &:nth-child(5n+4){background: linear-gradient(138deg, #0AB35F, #22DD7F);}
                    }
                    

                }
            }
        }
        
    }

</style>